import React from "react";
import { Table, Menu, Dropdown, Modal } from "antd";
import { EllipsisOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { connect, useSelector } from "react-redux";
import { updateTargets } from "../../actions/targets";
import _ from "lodash";

import "./styles.css";

const MenuTargetTable = (props) => {

  const { selectedTarget, setSelectedTarget, updateTargets } = props;

  const assignedTargets = useSelector((state) => state.menu.menu.targets);

  const targetsStatus = useSelector((state) => state.menu.targetsStatus);

  const menu = useSelector((state) => state.menu.menu);

  const { Item } = Menu;

  const { confirm } = Modal;

  function showConfirm() {
    confirm({
      title: `Are you sure you want to remove this target: ${_.get(selectedTarget, "name")}?`,
      icon: <ExclamationCircleOutlined />,
      centered: true,
      onOk() {
        const newTargets = _.filter(assignedTargets, ({ id }) => id !== selectedTarget.id);
        updateTargets(menu.id, newTargets);
        setSelectedTarget(null);
      },
      onCancel() {
        console.log("canceled");
      },
    });
  }

  const dropdownMenu = (
    <Menu>
      <Item
        key="0"
        onClick={() => {
          showConfirm();
        }}
      >
        Remove Target
      </Item>
    </Menu>
  );

  const data = _.map(assignedTargets, (target) => ({
    id: target.id,
    key: target.id,
    name: target.name,
    hours: target.hours,
    dayOfWeekHours: target.dayOfWeekHours,
    categories_disabled: target.categories_disabled,
    closedDates: target.closedDates,
    locationName: target.locationName,
    dayOfWeekClosures: target.dayOfWeekClosures,
  }));

  const columns = [
    {
      title: "target",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "actions",
      dataIndex: "actions",
      key: "actions",
      align: "right",
      width: 50,
      render: () => (
        <Dropdown overlay={dropdownMenu} overlayStyle={{ paddingRight: 10 }} trigger={["click"]}>
          <EllipsisOutlined rotate={90} />
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <h2 className="label">Targets</h2>
      <div className="targets-table-wrapper">
        <Table
          className="targets-table"
          size="small"
          showHeader={false}
          columns={columns}
          dataSource={data}
          pagination={false}
          loading={targetsStatus === "pending"}
          rowClassName={(record, index) =>
            selectedTarget && record.id === selectedTarget.id ? "highlighted-row" : null
          }
          onRow={(record, rowIndex) => {
            return {
              onClick: () => setSelectedTarget(record),
            };
          }}
        />
      </div>
    </>
  );
};

export default connect(null, { updateTargets })(MenuTargetTable);
